import { useState, useCallback, useMemo, useEffect, useContext } from "react";
import DynamicDataTableWithCount from "../components/dataTableWithCount";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal, Form, Col, Row, Container } from "react-bootstrap";
import APIServicenew from "../utils/APIGeneralService";
import Cookies from "universal-cookie";
import { setCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Papa from "papaparse";
import { useToasts } from "react-toast-notifications";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { formateDate } from "../utils/Helper";
import Select from 'react-select';

function Customers() {
  document.title = "Customers";
  const cookies = new Cookies();
  let token = cookies.get("accessToken");
  let astroUserId = cookies.get("astroUserId");
  const { refreshToken } = useContext(UserContext);
  const navigate = useNavigate();
  const { user: adminUser } = useContext(UserContext);
  const leadToken = cookies.get("accessTokenleads");
  const leadHeaders = { Authorization: `Bearer ${leadToken}` };
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [show, setShow] = useState(false);
  const [countData,setCountData]=useState()
  const [source, setSource] = useState("");
  const [signUpUtmSource, setSignUpUtmSource] = useState("");
  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [country, setCountry] = useState("");
  const [isVerified, setIsVerified] = useState("");
  const [isNotes, setIsNotes] = useState("");
  const [flag, setFlag] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [userForNote, setUserForNote] = useState();
  const [error, setError] = useState(false);
  const [note, setNote] = useState("");
  const [loader, setLoader] = useState(false);
  const { addToast } = useToasts();

  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  // const [countryOption, setCountryOption] = useState([
  //   { name: "India", id: "65effd4325ca275efc0d2f67" },
  //   { name: "USA", id: "65effee925ca275efc0d3651" },
  // ]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRange, setSelectedRange] = useState("");
  const [countryList,setcountryList]=useState([])

  const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };
  // for user calling modal
  const [dial, setDail] = useState("Call");
  const [showCallCm, setShowCallCm] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const handleCloseCallCm = () => {
    setShowCallCm(false);
  };
  const APIServiceschatbot = new APIServicenew(
    process.env.REACT_APP_OTHER_API_URL
  );
  const APIServicesSec = new APIServicenew(process.env.REACT_APP_REST_API_URL);
  
  const userCall = async () => {
    const res = await APIServiceschatbot.post(
      "responders/call",
      {
        userId: currentUserId,
        source: "customers",
      },
      leadHeaders
    );

    if (res && res.status === 200) {
      setDail(res?.data?.message);
      setShowCallCm(false);
      addToast("Calling....", {
        appearance: "success",
        autoDismiss: true,
      });
      setTimeout(() => {
        setDail("Call");
      }, 10000);
    }
  };

  const handleConfirm = () => {
    userCall();
  };

  const logout = () => {
    refreshToken(null);
    setCookies("accessTokenleads", null);
    navigate("/");
  };

  const handleInputChange = (date) => {
    const formattedDate = moment(date).format("MM-DD-YYYY");
    setStartDate(formattedDate);
    // setStartDate(prevState => ({ ...prevState, startdate: formattedDate }));
  };
  const handleInputChangeEnd = (date) => {
    // const formattedDate = moment(date).format('YYYY-MM-DD');
    const formattedDate = moment(date).format("MM-DD-YYYY");
    setEndDate(formattedDate);
  };

  const statusMap = {
    true: { text: "VERIFIED", testClass: "text-success fw-bold" },
    false: { text: "UNVERIFIED", testClass: "text-danger fw-bold" },
  };

  const columns = useMemo(() => [
    {
      Header: "NO.",
      Cell: (e) => (
        <span>
          {e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}
        </span>
      ),
    },
    {
      Header: "Name",
      Cell: (e) => {
        return <span>{e.row.original.name ?? "_"}</span>;
      },
    },
    {
      Header: "Country",
      Cell: (e) => {
        return (
          <span>{e.row.original.country ?? "_"}</span>
        );
      },
    },
    {
      Header: "Email / Mobile Number",
      Cell: (e) => {
        return (
          <>
            <span className="text-lowercase">{e.row.original.email ?? "_"}</span>
            <br />
            <span>
              {/* {e.row.original.currency === "INR"
                ? "91" + e.row.original.contactNumber
                : "1" + e.row.original.contactNumber} */}
                  {e.row.original.contactNumber}
            </span>
            <br />
            <button
              onClick={() => {
                setShowCallCm(true);
                setCurrentUserId(e.row.original?._id);
              }}
              className="mt-2  call_btn btn btn-secondary"
            >
              Call to User
            </button>
          </>
        );
      },
    },
    {
      Header: "Gender",
      Cell: (e) => {
        return <span>{e.row.original.gender ?? "_"}</span>;
      },
    },
    {
      Header: "Source",
      Cell: (e) => {
        return <span>{e.row.original.utmSource ?? "_"}</span>;
      },
    },
    {
      Header: "Signup Source",
      Cell: (e) => {
        return <span>{e.row.original.signUpUtmSource ?? "_"}</span>;
      },
    },
    {
      Header: "Verified",
      Cell: (e) => {
        return (
          <span className={statusMap[e.row.original.isVerified]?.testClass}>
            {statusMap[e.row.original.isVerified]?.text}
          </span>
        );
      },
    },
    {
      Header: "Registration Date",
      Cell: (e) => {
        // return <span>{moment(e.row.original.createdAt).format("lll")}</span>;
        return <span>{moment(e.row.original.createdAt).format("D MMM, YYYY h:mm A")}</span>;
      },
    },
    {
      Header: "Last Note Date",
      Cell: (e) => {
        return (
          <span>
            {e.row.original?.lastNote
              ? moment(e.row.original?.lastNote?.updatedAt).format("D MMM, YYYY h:mm A")
              : "_"}
          </span>
        );
      },
    },
    {
      Header: "Last Note",
      Cell: (e) => {
        const lastMessage = e.row.original?.lastNote?.last_message ?? "_";
        const truncatedMessage =
          lastMessage.length > 20
            ? lastMessage.substring(0, 10) + "..."
            : lastMessage;
        return (
          <>
            <h6 className="text-primary">
              {e.row.original?.lastNote?.last_adminName
                ? e.row.original?.lastNote?.last_adminName
                : e.row.original?.lastNote?.last_responderName}
            </h6>
            <span title={lastMessage}>{truncatedMessage}</span>
          </>
        );
      },
    },
    {
      Header: "Notes",
      Cell: (e) => {
        if (e.row.original._id) {
          return (
            <h5>
              <input
                type="button"
                className="Et-btn notes-btn"
                value="Notes"
                onClick={() => {
                  setUserForNote(e.row.original);
                  handleUserNotes(e.row.original._id);
                }}
              />
            </h5>
          );
        }
      },
    },
  ]);

  useEffect(()=>{
    authorizeResponder()
    fetchCountryList()
  },[])

  const fetchCountryList=async()=>{
    let response = await APIServicesSec.get(`country/getPhoneCode`, leadHeaders);

    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setcountryList(apiData?.data)
      }
    }
  }
  const handleSelectChange = selected => {
    setCountry(selected);
    setSkip(0)
 };

const options = countryList.map(item => ({
       value: item._id,
       label: item.name,
}));

  
  const authorizeResponder =async()=>{
    const res = await APIServiceschatbot.post(
      "responders/authorize_responder",
        {
        },
        leadHeaders
      );
      if (res.data?.status == 4) {
        logout();
      }
    }
  


  const handleCountData = async (id) => {
    const res = await APIServices.get(`lead/getUserCount`, leadHeaders);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        setCountData(res.data.resData);
      } else {
        console.log("error");
      }
    }
  };

  const fetchContactList = useCallback(
    async (skip, limit, index) => {
 
      try {
        // let page =skip/limit+1
        // console.log("🚀 ~ page:", page)
        let countryId = country?.value === undefined ? '' : country?.value;
        const response = await APIServices.get(
          `lead/usersList/${skip}/${limit}?name=${name}&email=${email}&contactNumber=${number}&startDate=${startDate ? formateDate(startDate) : ""}&endDate=${endDate ? formateDate(endDate) : ""}&countryId=${countryId}&isVerified=${isVerified=="all"?"":isVerified}&source=${source=="all"?"":source}&signUpUtmSource=${signUpUtmSource=="all"?"":signUpUtmSource}&gender=${gender=="all"?"":gender}&isNotes=${isNotes=="all"?"":isNotes}`,
          leadHeaders
        );
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.resData?.total_count / limit));
          setAllDataCount(response.data?.resData?.total_count);
          setTableData(response.data?.resData?.data);
          setFlag(false);
          window.scrollTo({ top: 0 });
        } else {
          if (response?.response?.data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [
      pageIndex,
      skip,
      name,
      email,
      number,
      startDate,
      endDate,
      country,
      isVerified,
      source,
      signUpUtmSource,
      gender,
      isNotes,
    ]
  );

  const handleFilterClick = () => {
    if (
      name ||
      email ||
      number ||
      startDate ||
      endDate ||
      skip ||
      country ||
      isVerified ||
      source ||
      signUpUtmSource ||
      gender ||
      isNotes
    ) {
      fetchContactList(
        skip,
        limit,
        0,
        name,
        email,
        number,
        startDate,
        endDate,
        country,
        isVerified,
        source,
        signUpUtmSource,
        gender,
        isNotes
      );
    }
  };

  const handleNotesAdd = async () => {
    if (!note || note === "") {
      setError(true);
    } else {
      let payload = {
        userId: userForNote?._id,
        source:"Leads-Customer",
        type: "responder",
        adminName: adminUser?.name,
        adminId: adminUser?._id,
        message: note,
      };
      setLoader(true);
      const res = await APIServices.post(
        `userNotes/userNoteMessage`,
        payload,
        leadHeaders
      );

      if (res && res?.data?.status === true) {
        toast.success("Note added successfully");
        setFlag(true);
        setShow(false);
        // getUserNotes(userForNote?.userId)
        setNote("");
        setLoader(false);
      } else {
        toast.error(res?.data?.message);

        setLoader(false);
      }
      setLoader(false);
    }
  };
  const handleChange = () => {
    setError(false);
  };
  const handleCloseModal = () => {
    setShow(false);
    setError(false);
  };

  const handleUserNotes = async (id) => {
    const res = await APIServices.get(`userNotes/getDetailById/${id}`, leadHeaders);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        let notes = res.data.data?.notes.reverse();

        setNotesData(res.data.data);
        setShow(true);
      } else {
        console.log("error");
      }
    }
  };
  const resetData = () => {
    setNumber("");
    setEmail("");
    setName("");
    setStartDate("");
    setEndDate("");
    setSelectedRange("")
    setCountry("");
    setIsVerified("");
    setIsNotes("");
    setSource("");
    setSignUpUtmSource("");
    setGender("");
    setFlag(true);
  };

  useEffect(() => {
    fetchContactList(skip, limit, pageIndex);
    handleCountData()
  }, [flag]);

  const handleDownload = () => {
    for (let item of tableData) {
      if (item.name) item["Name"] = item.name ?? "_";
      if (item.contactNumber) item["Mobile Phone"] = item.contactNumber;
      if (item.email) item["Email"] = item.email ?? "_";
      if (item.country) item["Country"] = item.country;
      if (item.utmSource) item["Source"] = item.utmSource;
      if (item.signUpUtmSource) item["signUp Source"] = item.signUpUtmSource;
      if (item.isVerified)
        item["Verified"] = item.isVerified === true ? "Verified" : "UnVerified";
      if (item.gender) item["Gender"] = item.gender;
      item["Created Date "] = moment(item?.createdAt).format("lll");
      item["Updated Date "] = moment(item?.updatedAt).format("lll");
      // Add additional fields as needed

      // Delete unwanted fields
      delete item.name;
      delete item.contactNumber;
      delete item.email;
      delete item.countryId;
      delete item.country;
      delete item._id;
      delete item.countryCode;
      delete item.currency;
      delete item.phoneCode;
      delete item.profileImage;
      delete item.utmSource;
      delete item.signUpUtmSource;
      delete item.gender;
      delete item.isVerified;
      delete item.status;
      delete item.wallet;
      delete item.isDeleted;
      delete item.createdAt;
      delete item.updatedAt;
      delete item.__v;
      // Delete additional unwanted fields
    }

    let csv = Papa.unparse(tableData, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(csvData);
    } else {
      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(csvData, { type: "text/plain" });
      a.setAttribute("download", "userList_data.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <div className="App">
      <div className="gita_quote_heading">
        <div className="container">
          <div className="overflow-x-auto">
            <div className="d-flex counter-wrapper gap-2 align-items-center justify-content-between">
              <h4 className="hd-list-f font_20 left-q counter-top">
              Total Customers <small>{allDataCount}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Today Customers <small>{countData?.usersAddedToday}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                This Week Customers <small>{countData?.usersAddedThisWeek}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                This Month Customers <small>{countData?.usersAddedThisMonth}</small>
              </h4>
              <div class="_btn">
                <Button
                  className=" as_btn"
                  variant="primary"
                  onClick={(e) => {
                    navigate(`/addquestion`);
                  }}
                >
                  Add Customer
                </Button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <ToastContainer />
      <div className="chat_window">
        <Row className="mlist br-6 p-20">
            <div class="chat_search_box user-search">
              <div className="chat-search-field">
                  <Form>
                    <div className="customer-calendar">
                      <Form.Group as={Row} className="" controlId="">
                        <Col md={6} lg={3}>
                          <Form.Label class="label-f-w-cd">
                            Name
                          </Form.Label>

                          <Form.Control
                            className="mt-2 mb-3 form-ch"
                            name="name"
                            type="search"
                            placeholder="Search By Name"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                              setSkip(0);
                            }}
                          />
                        </Col>

                        <Col md={6} lg={3}>
                          <Form.Label class="label-f-w-cd">
                            Email
                          </Form.Label>

                          <Form.Control
                            className="mt-2 mb-3 form-ch"
                            name="email"
                            type="search"
                            placeholder="Search By Email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setSkip(0);
                            }}
                          />
                        </Col>

                        <Col md={6} lg={3}>
                          <Form.Label class="label-f-w-cd">
                            Mobile Number
                          </Form.Label>

                          <Form.Control
                            className="mt-2 mb-3 form-ch"
                            name="number"
                            type="text"
                            placeholder="Search By Mobile Number"
                            value={number}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              // Regular expression to allow only numbers (no decimal point, no letters)
                              if (/^\d*$/.test(inputValue)) {
                                setNumber(inputValue); // Only update the state if the input is a valid number
                                setSkip(0);
                              }
                            }}
                            maxLength={10}
                          />
                        </Col>

                        <Col lg={3} md={6} className='mb-3'>
                                <Form.Label class='label-f-w-cd'>
                                  Country
                                </Form.Label>
                                  <Select
                                   className=' mt-2'
                                    classNamePrefix='react-select'
                                    options={options}
                                    value={country}
                                    onChange={handleSelectChange}
                                    placeholder='Search by Country...'
                                    isClearable
                                  />
                            </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="align-items-end" controlId="">
                        <Col md={6} lg={3}>
                          <Form.Label class="label-f-w-cd">
                            Verified
                          </Form.Label>

                          <Form.Select
                            aria-label="label-selected-menu"
                            className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 mb-3 form-ch"
                            onChange={(e) => {
                              setIsVerified(e.target.value);
                              setSkip(0);
                            }}
                            value={isVerified}
                          >
                            <option className="fw-bold" value="all">All</option>
                            <option className="text-capitalize" value="yes">
                              Yes
                            </option>
                            <option className="text-capitalize" value="no">
                              No
                            </option>
                          </Form.Select>
                        </Col>
                        <Col md={6} lg={3}>
                          <Form.Label class="label-f-w-cd">
                            Source
                          </Form.Label>

                          <Form.Select
                            aria-label="label-selected-menu"
                            className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 mb-3 form-ch"
                            onChange={(e) => {
                              setSource(e.target.value);
                              setSkip(0);
                            }}
                            value={source}
                          >
                            <option className="fw-bold" value="all"> All </option>
                            <option className="text-capitalize" value="web">
                              {" "}
                              Web{" "}
                            </option>
                            <option
                              className="text-capitalize"
                              value="whatsApp"
                            >
                              {" "}
                              WhatsApp
                            </option>
                            <option className="text-capitalize" value="mobile">
                              {" "}
                              Mobile
                            </option>
                            <option
                              className="text-capitalize"
                              value="instagram"
                            >
                              {" "}
                              Instagram
                            </option>
                            <option
                              className="text-capitalize"
                              value="facebook"
                            >
                              Facebook
                            </option>
                            <option className="text-capitalize" value="global">
                              Global
                            </option>
                          </Form.Select>
                        </Col>
                        <Col md={6} lg={3}>
                          <Form.Label class="label-f-w-cd">
                            {" "}
                            SignUp Source
                          </Form.Label>

                          <Form.Select
                            aria-label="label-selected-menu"
                            className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 mb-3 form-ch"
                            onChange={(e) => {
                              setSignUpUtmSource(e.target.value);
                              setSkip(0);
                            }}
                            value={signUpUtmSource}
                          >
                            <option className="fw-bold" value="all"> All </option>
                            <option className="text-capitalize" value="web">
                              {" "}
                              Web{" "}
                            </option>
                            <option
                              className="text-capitalize"
                              value="whatsApp"
                            >
                              {" "}
                              WhatsApp
                            </option>
                            <option className="text-capitalize" value="mobile">
                              {" "}
                              Mobile
                            </option>
                            <option className="text-capitalize" value="puja">
                              {" "}
                              Puja
                            </option>
                            <option
                              className="text-capitalize"
                              value="instagram"
                            >
                              {" "}
                              Instagram
                            </option>
                            <option
                              className="text-capitalize"
                              value="facebook"
                            >
                              Facebook
                            </option>
                            <option className="text-capitalize" value="global">
                              Global
                            </option>
                          </Form.Select>
                        </Col>
                        <Col md={6} lg={3}>
                          <Form.Label class="label-f-w-cd">
                            {" "}
                            Gender
                          </Form.Label>

                          <Form.Select
                            aria-label="label-selected-menu"
                            className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 mb-3 form-ch"
                            onChange={(e) => {
                              setGender(e.target.value);
                              setSkip(0);
                            }}
                            value={gender}
                          >
                            <option className="fw-bold" value="all"> All</option>
                            <option className="text-capitalize" value="male">
                              Male{" "}
                            </option>
                            <option className="text-capitalize" value="female">
                              {" "}
                              Female{" "}
                            </option>
                            <option className="text-capitalize" value="other">
                              {" "}
                              Other{" "}
                            </option>
                          </Form.Select>
                        </Col>
                        <Col md={6} lg={3}>
                          <Form.Label class="label-f-w-cd">
                            {" "}
                            Notes
                          </Form.Label>

                          <Form.Select
                            aria-label="label-selected-menu"
                            className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 mb-3 form-ch"
                            onChange={(e) => {
                              setIsNotes(e.target.value);
                              setSkip(0);
                            }}
                            value={isNotes}
                          >
                            <option className="fw-bold" value="all"> All</option>
                            <option className="text-capitalize" value="yes">
                              Yes{" "}
                            </option>
                            {/* <option className='text-capitalize' value='no'> No </option> */}
                          </Form.Select>
                        </Col>
                        <Col md={6} lg={3}>
                            <Form.Label class="label-f-w-cd">
                              Search By Registration Date
                            </Form.Label> 
                            <div className="date-range-wrapper mt-2 mb-3">
                              <div className='select-icon-wrapper'>  
                                  <select
                                className="select-range"
                                    value={selectedRange}
                                    onChange={(e) => handleDateRange(e.target.value)}
                                  >
                                    <option value="">Select Date Range</option>
                                    <option value="today">Today</option>
                                    <option value="yesterday">Yesterday</option>
                                    <option value="thisWeek">This Week</option>
                                    <option value="last7Days">Last 7 Days</option>
                                    <option value="thisMonth">This Month</option>
                                    <option value="lastMonth">Last Month</option>
                                    <option value="customDate">Custom Date</option>
                                  </select>
                                  <img src='/images/down-arrow.png' className='select-icons'/>
                              </div>

                                  {selectedRange === "customDate" && (
                                    <DatePicker
                                      selectsRange={true}
                                      startDate={startDate}
                                      endDate={endDate}
                                      maxDate={new Date()}
                                      onChange={(update) => {
                                        const [start, end] = update;
                                        setStartDate(start);
                                        setEndDate(end);
                                      }}
                                      // isClearable={true}
                                      placeholderText="Select custom date range"
                                      dateFormat="yyyy-MM-dd"
                                    />
                                  )}
                               </div>
                         </Col>

                        <Col className="mb-3" md={6} lg={3}>
                                  <Button
                                    variant="secondary"
                                    className="as_btn ms-0"
                                    onClick={() => {
                                      handleFilterClick();
                                      setSkip(0);
                                    }}
                                  >
                                    Filter
                                  </Button>
                                  <Button
                                    variant="secondary"
                                    className="as_btn reset-btn"
                                    onClick={() => {
                                      resetData();
                                    }}
                                  >
                                    Reset
                                  </Button>
                          </Col>
                      </Form.Group>
                    </div>
                  </Form>
              </div>
            </div>
        </Row>

                        {/* <Col className="mt-2" md={2}>
                         
                            <Form.Label class="label-f-w-cd  my-1 ">
                               Select by start date
                            </Form.Label>
                          <DatePicker
                            placeholderText={"Select by start date"}
                            className="form-control form-ch fil-date-pick"
                            name="startdate"
                            showIcon
                            toggleCalendarOnIconClick
                            selected={startDate ? new Date(startDate) : null}
                            onChange={(date) => {
                              handleInputChange(date);
                              setSkip(0);
                            }}
                            dateFormat="yyyy-MM-dd"
                          />
                        </Col>
                        

                        <Col className="mt-2" md={2}>
                         
                          <Form.Label class="label-f-w-cd  my-1 ">
                               Select by end date
                            </Form.Label>
                          <DatePicker
                            placeholderText={"Select by end date"}
                            className="form-control form-ch fil-date-pick"
                            name="enddate"
                            showIcon
                            toggleCalendarOnIconClick
                            selected={endDate ? new Date(endDate) : null}
                            onChange={(date) => {
                              handleInputChangeEnd(date);
                              setSkip(0);
                            }}
                            dateFormat="yyyy-MM-dd"
                          />
                        </Col> */}

                         
                        {/* <Button
        className='as_btn ms-2'
        variant='primary'
        disabled={!tableData || (tableData && tableData.length <= 0)}
        onClick={() => {
          // handleDownload();
        }}
      >
        Download File
      </Button> */}
                   
                
                {tableData && tableData?.length > 0 ? (
                    <DynamicDataTableWithCount
                      columns={columns}
                      data={tableData}
                      totalCount={pageCount}
                      initialState={{
                        pageIndex: pageIndex,
                        pageSize: limit,
                      }}
                      fetchData={fetchContactList}
                      forcePage={skip / limit}
                    />
                  ) : (
                    <h2 className="no_records">Records not Available</h2>
                  )}
        {/* </div> */}
        
      </div>
      {/* for user notes  */}
      <Modal
        show={show}
        size="md"
        // aria-labelledby='example-modal-sizes-title-sm'
        scrollable={true}
        backdrop="static"
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header className="modal-header-hd" closeButton>
          <Modal.Title> Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="aa d-flex flex-wrap">
            <span className="me-3"> Name :- {userForNote?.name} </span>
            <span> Contact Number :- 
            {userForNote?.currency === "INR"
                ? "91" + userForNote?.contactNumber
                : "1" + userForNote?.contactNumber}
                 </span>
          </div>

          <Row>
            <Col>
              <Form.Group as={Row} className="my-3" controlId="formAartiInput">
                <Col sm="12">
                  <Form.Control
                    as="textarea"
                    name="content"
                    placeholder="Type here..."
                    value={note}
                    style={{ height: 100 }}
                    onChange={(e) => {
                      handleChange();
                      setNote(e.target.value);
                    }}
                  />
                  {error ? (
                    <p className="text-danger">Please enter your note</p>
                  ) : (
                    ""
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Modal.Footer className="py-2 px-0">
            {loader ? (
              <Button variant="primary" className="as_btn reset-btn">
                Processing...
              </Button>
            ) : (
              <Button
                variant="primary"
                className="as_btn"
                onClick={handleNotesAdd}
              >
                Add
              </Button>
            )}
            <Button
              variant="secondary"
              className="as_btn reset-btn"
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>

          <h6 className="aa mt-2"> Notes History:-</h6>

          {notesData?.notes && notesData?.notes?.length>0 && notesData?.notes?.length > 0 ? (
            <>
              {notesData?.notes?.map((item, index) => (
                <>
                 <Row>
                          {item?.source && item?.source != "" ?  
                              <Col xs={5}>
                                  <span className="aa">Source :- </span>
                                    <span className="item-msg">{item.source ? item.source : "" } </span>
                              </Col> : ""
                          }

{item?.source && item?.source !== "" && (
    (item.source === "Leads-Chat-Orders" || item.source === "Leads-Call-Orders") ? (
        item?.sourcename && item.sourcename !== "" && (
            <Col xs={7}>
                {(item.source === "Leads-Chat-Orders") ? (
                    <span className="aa">Chat Id :- </span>
                ) : (
                    <span className="aa">Call Id :- </span>
                )}
                <span className="item-msg">
                    {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : (item.source === "Leads-Chadhawa-List" || 
         item.source === "Leads-Manokamna-List" || 
         item.source === "Marketing-Chadhawa-List" ||
         item.source === "Marketing-Manokamna-List") ? (
        item?.sourceid && item.sourceid !== "" && (
            <Col xs={7}>
                {(item.source === "Leads-Chadhawa-List" || item.source === "Marketing-Chadhawa-List") ? (
                    <span className="aa">Chadawa Id :- </span>
                ) : (
                    <span className="aa">Manokmana Id :- </span>
                )}
                <span className="item-msg">
                    {item?.sourceid?.length <= 50 ? item?.sourceid : `${item?.sourceid?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : (item.source === "Leads-Puja-Booking" || 
         item.source === "Leads-Puja-Suggestion" ||
         item.source === "Marketing-Puja-Booking" ||
         item.source === "Marketing-Puja-Feedback" ||
         item.source === "Marketing-Puja-Suggestion") ? (
        item?.sourcename && item.sourcename !== "" && item?.sourceid && (
            <Col xs={7}>
                <span className="aa">Puja Name :- </span>
                <span className="item-msg">
                        {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : null
)}
                </Row>
                  <Row className="notes-item">
                    <Col xs={9}>
                    <span className="item-name aa">
                              Note :- 
                      </span>
                      <span className="item-msg">{item?.message}</span>
                    </Col>
                    <Col xs={3}>
                      <div className="text-end d-flex flex-column">
                        <span className="text-primary item-name aa">
                          {item?.responderName || item?.adminName}
                        </span>
                        <span className="item-text">
                          {item?.createdAt
                            ? moment(item?.createdAt).format("D MMM, YYYY h:mm a")
                            : ""}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </>
              ))}
            </>
          ) : (
            <>
              <h4 className="text-center text-secondary">Not Available</h4>
            </>
          )}
        </Modal.Body>
      </Modal>

      {/* user call confirmation */}
      <Modal
        show={showCallCm}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseCallCm}
        centered
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to make a User call?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="as_btn"
            onClick={(e) => handleConfirm()}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="as_btn reset-btn"
            onClick={handleCloseCallCm}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default Customers;
